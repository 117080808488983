.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px; /* Add some space between cards */
}

.card {
  transition: transform 0.2s ease, opacity 0.2s ease;
  cursor: pointer;
  flex-basis: calc(20% - 20px); /* Initially, 5 cards in a row with some space between them */

  /* Responsive layout */
  @media (max-width: 1024px) {
    flex-basis: calc(33.33% - 20px); /* Display 3 cards in a row on tablets */
  }

  @media (max-width: 768px) {
    flex-basis: calc(100% - 20px); /* Display 1 card per row on mobile */
  }
}

.card:hover {
  transform: scale(1.1); /* Zoom in by 10% on hover */
  opacity: 1; /* Increase opacity of the hovered card */
}

.card:not(:hover) {
  opacity: 0.7; /* Decrease opacity of other cards */
}
.card .MuiCardMedia-root .MuiCardMedia-media .MuiCardMedia-img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the entire image is visible within the container */
  object-position: center center; /* Center the image horizontally and vertically */
}
